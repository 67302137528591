import { ADD_QUESTION_ACTIONS } from './addQuestionActions';
import { FETCH_QUESTIONS_ACTIONS } from './fetchQuestions';
import { QUESTIONS_ACTION } from './questionsAction';
import { DELETE_QUESTION_ACTIONS } from './deleteActions';
import { DRAG_AND_DROP_ACTIONS } from './dragAndDropAction';

import type { QuestionType, QuestionMetaDataObjectType } from '../../../types/questionTypes';
import { EDIT_QUESTION_ACTIONS } from './editQuestionActions';

export const initialQuestions = [
  {
    id: 'banner',
    quizId: 1,
    questionId: 'banner',
    type: 'BANNER',
    solutionInfo: null,
    questionBody: null,
    durationInSecs: 15,
    leaderboard: false,
    showSolution: false,
  },
  {
    id: 'joining',
    quizId: 1,
    questionId: 'joining',
    type: 'JOINING',
    solutionInfo: null,
    questionBody: null,
    durationInSecs: 15,
    leaderboard: false,
    showSolution: false,
  },
];

export const initialQuestionMetaData: QuestionMetaDataObjectType[] = [
  {
    id: 'banner',
    type: 'BANNER',
    hidden: false,
  },
  {
    id: 'joining',
    type: 'JOINING',
    hidden: false,
  },
];

type initialStateType = {
    questionsArray: QuestionType[],
    questionMetaData: QuestionMetaDataObjectType[],
    consistencyData: any,
    currentIndex: number,
    loading: boolean,
    error: any,
};

export const initialState: initialStateType = {
  questionsArray: initialQuestions,
  currentIndex: 0,
  loading: true,
  error: null,
  questionMetaData: initialQuestionMetaData,
  consistencyData: {},
};

// eslint-disable-next-line default-param-last
export default function questionsReducer(state = initialState, action: any) {
  switch (action.type) {
    case DRAG_AND_DROP_ACTIONS.DRAG_AND_DROP_LOADING:
    case ADD_QUESTION_ACTIONS.ADD_QUESTION_LOADING:
    case EDIT_QUESTION_ACTIONS.EDIT_QUESTION_LOADING:
    case DELETE_QUESTION_ACTIONS.DELETE_QUESTION_LOADING:
    case FETCH_QUESTIONS_ACTIONS.FETCH_QUESTIONS_LOADING:
      return { ...state, loading: true };
    case ADD_QUESTION_ACTIONS.ADD_QUESTION: {
      const addAfterIndex = state.currentIndex !== 0 ? state.currentIndex : 1;
      return {
        ...state,
        questionsArray: [...state.questionsArray.slice(0, addAfterIndex + 1), action.question, ...state.questionsArray.slice(addAfterIndex + 1)],
        loading: false,
        questionMetaData: [...initialQuestionMetaData, ...action.questionMetaData],
        currentIndex: addAfterIndex + 1,
      };
    }
    case DRAG_AND_DROP_ACTIONS.DRAG_AND_DROP_ERROR:
    case ADD_QUESTION_ACTIONS.ADD_QUESTION_ERROR:
    case EDIT_QUESTION_ACTIONS.EDIT_QUESTION_ERROR:
    case FETCH_QUESTIONS_ACTIONS.FETCH_QUESTIONS_ERROR:
    case DELETE_QUESTION_ACTIONS.DELETE_QUESTION_ERROR:
      return { ...state, error: action.error, loading: false };
    case FETCH_QUESTIONS_ACTIONS.FETCH_QUESTIONS:
      return {
        ...state,
        questionsArray: [...initialQuestions, ...action.data.questions],
        loading: false,
        questionMetaData: [...state.questionMetaData, ...action.questionMetaData],
        currentIndex: action.data.currentIndex !== undefined ? action.data.currentIndex + 2 : 0,
        consistencyData: action.data.consistencyData ?? {},
      };
    case QUESTIONS_ACTION.CHANGE_CURRENT_INDEX:
      return { ...state, currentIndex: action.index };
    case DELETE_QUESTION_ACTIONS.DELETE_QUESTION:
      return {
        ...state,
        questionsArray: [...state.questionsArray.slice(0, state.currentIndex), ...state.questionsArray.slice(state.currentIndex + 1)],
        currentIndex: state.currentIndex - 1,
        loading: false,
        questionMetaData: [...initialQuestionMetaData, ...action.questionMetaData],
      };
    case DRAG_AND_DROP_ACTIONS.DRAG_AND_DROP:
      return {
        ...state, questionsArray: action.questionsArray, questionMetaData: action.questionMetaData, currentIndex: action.newIndex, loading: false,
      };
    case EDIT_QUESTION_ACTIONS.EDIT_QUESTION: {
      const currentIndexData = {
        ...state.questionsArray[state.currentIndex], durationInSecs: action.question.durationInSecs, leaderboard: action.question.leaderboard, showSolution: action.question.showSolution,
      };
      return { ...state, questionsArray: [...state.questionsArray.slice(0, state.currentIndex), currentIndexData, ...state.questionsArray.slice(state.currentIndex + 1)], loading: false };
    }
    default:
      return state;
  }
}
