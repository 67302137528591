var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"wr8ueHAAjvyyQU-8T2J0y"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import { BrowserTracing } from '@sentry/tracing';
import { CaptureConsole as CaptureConsoleIntegration } from '@sentry/integrations';
import { sentryDSN } from './config/common';

if (process.env.CONFIG_MODE === 'production') {
  // Sentry.init({
  //   dsn: sentryDSN,
  //   integrations: [new CaptureConsoleIntegration({
  //     levels: ['error'],
  //   }), new BrowserTracing()],
  //   environment: process.env.CONFIG_MODE,
  //   // Adjust this value in production, or use tracesSampler for greater control
  //   tracesSampleRate: 0.5,
  // // ...
  // // Note: if you want to override the automatic release value, do not set a
  // // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // // that it will also get attached to your source maps
  // });
}
