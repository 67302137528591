import { Profile, UserNames } from '../../../utils/enums';
import { STUDENT_ACTIONS } from './userActions';

// eslint-disable-next-line default-param-last
export default function studentReducer(state = {
  profile: Profile[Math.floor(Math.random() * 97) + 1],
  name: '',
  placeholder: UserNames[Math.floor(Math.random() * 194) + 1],
  success: false,
}, action: any) {
  switch (action.type) {
    case STUDENT_ACTIONS.SET_USER_DATA: {
      let profile = action.data.profile || state.profile;
      if (action.data.email && !action.profile) {
        profile = 'Group 51.svg';
      }
      return {
        ...state, name: action.data.fullName, profile, ...action.data, success: true,
      };
    }
    case STUDENT_ACTIONS.USER_UNAUTHORISED:
      return { ...state, success: action.data };
    default:
      return state;
  }
}
