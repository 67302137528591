export const STUDENT_ACTIONS = {
  SET_USER_DATA: 'SET_USER_DATA',
  USER_UNAUTHORISED: 'USER_UNAUTHORISED',
};

export const setUserData = (data: any) => ({
  type: STUDENT_ACTIONS.SET_USER_DATA,
  data,
});

export const setUserDataWhenUnAuthorised = (data: any) => ({
  type: STUDENT_ACTIONS.USER_UNAUTHORISED,
  data,
});
