export const COMMON_ACTION = {
  SHOW_TOAST: 'SHOW TOAST',
};

export const showToast = (message: string | null) => ({
  type: COMMON_ACTION.SHOW_TOAST,
  message,
});

export const currentServerTime = () => async (dispatch: any, getState: any) => {
  const { common } = getState();
  const timeDiff = common.timeDiff.timeDifference;
  if (timeDiff) {
    const diff = timeDiff * -1;
    return Date.now() - diff;
  }
  return Date.now();
};