/* eslint-disable @next/next/inline-script-id */
import '../styles/globals.scss';
import '../styles/frameworkStyles.scss';
import type { AppProps } from 'next/app';
import { Provider } from 'react-redux';
import Script from 'next/script';
import { setUser } from '@sentry/nextjs';
import { useEffect } from 'react';
import { store } from '../redux/store';
import { config } from '../config/config';
import { getAbsImagePath } from '../utils/utilFunctions';
// import { qaAndProd } from '../utils/enums';

// import packageJson from '../package.json';

if (typeof window !== 'undefined') {
  // @ts-ignore
  window.config = config;
}

function MyApp({ Component, pageProps }: AppProps) {
  // const isWindowObj = typeof window !== 'undefined';
  const { userId, email, name } = store.getState().userData;
  useEffect(() => {
    if (userId) {
      setUser({
        id: userId,
        email,
        username: name,
      });
    } else {
      setUser(null);
    }
  }, [userId]);
  return (
    <Provider store={store}>
      <Script src={getAbsImagePath('/cdn/js/analytics/click-stream.js')} strategy="beforeInteractive" />
      {/* {isWindowObj && !window?.DD_LOGS && qaAndProd.includes(config.mode) && (
      <Script dangerouslySetInnerHTML={{
        __html: `(function(h,o,u,n,d) {
          h=h[d]=h[d]||{q:[],onReady:function(c){h.q.push(c)}}
          d=o.createElement(u);d.async=1;d.src=n
          n=o.getElementsByTagName(u)[0];n.parentNode.insertBefore(d,n)
        })(window,document,'script','https://www.datadoghq-browser-agent.com/datadog-logs-v4.js','DD_LOGS')
        DD_LOGS.onReady(function() {
            DD_LOGS.init({
              clientToken: '${config.ddClientTokenDDLOG}',
              site: 'datadoghq.com',
              forwardErrorsToLogs: true,
              sampleRate: 10,
              trackSessionAcrossSubdomains: true,
              service: 'quiz-service',
              env: '${config.mode.toLowerCase()}',
            })
          })`,
      }}
      />
      )} */}
      {/* {isWindowObj && !window?.DD_RUM && qaAndProd.includes(config.mode) && (
      <Script dangerouslySetInnerHTML={{
        __html: `(function(h,o,u,n,d) {
    h=h[d]=h[d]||{q:[],onReady:function(c){h.q.push(c)}}
    d=o.createElement(u);d.async=1;d.src=n
    n=o.getElementsByTagName(u)[0];n.parentNode.insertBefore(d,n)
  })(window,document,'script','https://www.datadoghq-browser-agent.com/datadog-rum-v4.js','DD_RUM')
  DD_RUM.onReady(function() {
    DD_RUM.init({
      clientToken: '${config.ddClientTokenRum}',
      applicationId: '${config.ddApplicationIdRum}',
      site: 'datadoghq.com',
      service:'quiz-service',
      version: ${Number(packageJson.version)},
      sampleRate: 10,
      trackInteractions: false,
      defaultPrivacyLevel: 'mask-user-input',
      env: '${config.mode.toLowerCase()}',
      replaySampleRate: 0,
      trackSessionAcrossSubdomains: true,
      allowedTracingOrigins: [${/https:\/\/.*\.vedantu\.com/}]
    });
  })`,
      }}
      />
      )} */}
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Component {...pageProps} />
    </Provider>
  );
}

export default MyApp;