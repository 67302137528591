import { TimeDifferenceData } from '../../../types/quiz';
import { COMMON_ACTION } from './commonAction';
import { TIME_DIFF_ACTIONS } from './getTimeDifference';

type initialStateType = {
    toastMessage: string | null,
    loading: boolean,
    timeDiff: TimeDifferenceData | null
    error: any
}

const initialState: initialStateType = {
  toastMessage: null,
  loading: false,
  timeDiff: null,
  error: null,
};

// eslint-disable-next-line default-param-last
const commonReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case COMMON_ACTION.SHOW_TOAST:
      return { ...state, toastMessage: action.message };
    case TIME_DIFF_ACTIONS.TIME_DIFF_LOADING:
      return { ...state, loading: true };
    case TIME_DIFF_ACTIONS.TIME_DIFF:
      return { ...state, loading: false, timeDiff: action.data };
    case TIME_DIFF_ACTIONS.TIME_DIFF_ERROR:
      return { ...state, error: action.error };
    default:
      return state;
  }
};

export default commonReducer;