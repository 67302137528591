import { QuestionType, QuestionMetaDataObjectType } from '../../../types/questionTypes';
import { fetchData } from '../../../utils/fetch';

import type { RootState } from '../../store';
import type { QuestionMetaDataResponseType } from '../../../types/questionTypes';
import { showToast } from '../common/commonAction';

export const DRAG_AND_DROP_ACTIONS = {
  DRAG_AND_DROP_LOADING: 'DRAG_AND_DROP_LOADING',
  DRAG_AND_DROP: 'DRAG_AND_DROP',
  DRAG_AND_DROP_ERROR: 'DRAG_AND_DROP_ERROR',
};

type returnStartAction = {
    type : string
  }

function start(): returnStartAction {
  return {
    type: DRAG_AND_DROP_ACTIONS.DRAG_AND_DROP_LOADING,
  };
}

type DragAndDropSuccessActionType = {
    type: string,
    questionMetaData: QuestionMetaDataObjectType[],
    questionsArray: QuestionType[],
    newIndex: number
  }

export const success = (questionsArray: QuestionType[], questionMetaData: QuestionMetaDataObjectType[], newIndex: number): DragAndDropSuccessActionType => ({
  type: DRAG_AND_DROP_ACTIONS.DRAG_AND_DROP,
  questionMetaData,
  questionsArray,
  newIndex,
});

  type DragAndDropQuestionActionErrorType = {
    type: string,
    error: any,
  }

function failure(error: any): DragAndDropQuestionActionErrorType {
  return {
    type: DRAG_AND_DROP_ACTIONS.DRAG_AND_DROP_ERROR,
    error,
  };
}

const getQuestionsAfterDrag = (questions: QuestionType[]| QuestionMetaDataObjectType[], currentIndex: number, newIndex: number) => {
    type accType = {
      nonSelectedQuestion: QuestionType[] | QuestionMetaDataObjectType[],
      selectedQuestion: QuestionType[] | QuestionMetaDataObjectType[],
    }
    // @ts-ignore --https://github.com/microsoft/TypeScript/issues/36390
    const { nonSelectedQuestion, selectedQuestion } = questions.reduce((acc: accType, question : QuestionType| QuestionMetaDataObjectType, index: number) => {
      if (index === currentIndex) {
        return {
          ...acc, selectedQuestion: [...acc.selectedQuestion, question],
        };
      }

      return {
        ...acc, nonSelectedQuestion: [...acc.nonSelectedQuestion, question],
      };
    }, { nonSelectedQuestion: [], selectedQuestion: [] });

    const newQuestions = [
      ...nonSelectedQuestion.slice(0, newIndex),
      ...selectedQuestion,
      ...nonSelectedQuestion.slice(newIndex, questions.length),
    ];

    return newQuestions;
};

const getQuestionMetaDataAfterDrag = (questionMetaData : QuestionMetaDataObjectType[], questionsArray: QuestionType[], currentIndex: number, newIndex: number) => {
  const currentQuestionId = questionsArray[currentIndex].id;
  const newIndexQuestionId = questionsArray[newIndex].id;
  let questionMetaDataCurrentIndex = 0;
  let questionMetaDataNewIndex = 0;
  questionMetaData.forEach((question: QuestionMetaDataObjectType, index: number) => {
    if (question.id === currentQuestionId) {
      questionMetaDataCurrentIndex = index;
    } else if (question.id === newIndexQuestionId) {
      questionMetaDataNewIndex = index;
    }
  });
  return getQuestionsAfterDrag(questionMetaData, questionMetaDataCurrentIndex, questionMetaDataNewIndex);
};

function postQuestionMetaData(questionMetaData: QuestionMetaDataObjectType[], quizId: number): Promise<QuestionMetaDataResponseType> {
  return fetchData({
    method: 'POST', url: `/question-metadata/v1/admin/quiz/${quizId}`, credentials: 'include', data: questionMetaData,
  });
}

export const updateQuestionArrayAfterDrag = (newIndex: number) => async (dispatch: any, getState: () => RootState) => {
  const state = getState();
  const { currentIndex, questionsArray, questionMetaData } = state.questions;
  const { quizId } = state.quizData.data;
  dispatch(start());
  const newQuestionsArray = getQuestionsAfterDrag(questionsArray, currentIndex, newIndex);
  const newQuestionMetaData = getQuestionMetaDataAfterDrag(questionMetaData, questionsArray, currentIndex, newIndex);
  await postQuestionMetaData(newQuestionMetaData.slice(2), quizId); // not sending first two
  try {
    dispatch(showToast('Questions sequence changed'));
    return dispatch(success(newQuestionsArray, newQuestionMetaData, newIndex));
  } catch (error) {
    // @ts-ignore
    dispatch(showToast(`Error while changing question sequence  ${error?.error?.message}`));
    return dispatch(failure(error));
  }
};