import { StatusCodes } from '../utils/enums';

enum QuizType {
    LIVE = 'LIVE',
    ON_DEMAND = 'ON_DEMAND',
    HYBRID = 'HYBRID',
}

enum AskSignUpType {
    BEFORE = 'BEFORE',
    AFTER = 'AFTER',
}

enum QuizProgressStates {
    LIVE_STARTED = 'LIVE_STARTED',
    LIVE_ENDED = 'LIVE_ENDED',
    ON_DEMAND_ENDED ='ON_DEMAND_ENDED',
}

type QuizEntityDataType = {
    quizId: number,
    type: QuizType,
    presenterId: number,
    subjects: string[],
    grade: string[],
    defaultQuestionTimeInSecs: number,
    defaultQuestionInternvalInSecs: number,
    title: string,
    entityState: string,
    startTime: number,
    liveEndTime: number,
    onDemandEndTime: number,
    thumbnail: string,
    ytUrl: string,
    createdBy: number,
    lastUpdated: number,
    creationTime: number,
    progressState: QuizProgressStates,
    totalQuestions: number,
    allowLateJoinees: boolean,
    defaultQuestionReadingTimeInSecs: number,
    redirectionUrl: string,
    flags: string[],
    players?: number,
    askSignup?: string,
};

type ResponseSuccessQuizEntityDataType = {
    response: QuizEntityDataType
};

type ResponseErrorQuizEntityDataType = {
    error: {
        code: StatusCodes,
        message: string
    }
};

type TimeDifferenceData = {
    timeDifference: number,
    serverTime: number,
    clientTime: number,
}

export type {
  QuizEntityDataType, ResponseSuccessQuizEntityDataType, ResponseErrorQuizEntityDataType, TimeDifferenceData,
};

export { QuizType, QuizProgressStates, AskSignUpType };
