enum StatusCodes {
    OK = 200,
    CREATED= 201,
    NO_CONTENT= 204,
    NOT_FOUND= 404,
    BAD_REQUEST= 400,
    SERVICE_ERROR= 500,
    FORBIDDEN_ERROR= 403,
    SERVICE_UNAVAILABLE= 503,
    GATEWAY_TIMEOUT= 504,
    CONFLICT= 409,
    UNAUTHORIZED= 401,
    TOO_MANY_REQUESTS= 429,
}

enum WebView {
  ANDROID= 'android-webview',
  MOBILE= 'mobile-webview',
  DESKTOP= 'desktop-webview',
}

enum DateUtils {
  MILLIS_PER_SECOND= 1000,
  SECONDS_PER_MINUTE = 60,
  MINUTES_PER_HOUR = 60,
}

enum PresenterQuestionStates {
  GET_READY_STATE= 1,
  ANSWER_FAST_STATE = 2,
  QUESTION_READING_STATE = 3,
  QUESTION_ANSWERING_STATE = 4,
  END_QUESTION = 5,
  SHOW_GRAPH = 6,
  GRAPH_RESULT = 7,
  SHOW_LEADERBOARD = 8,
  FINAL_LEADERBOARD = 9,
  END_QUIZ = 10,
}

enum Subsystem {
  QUIZ = 'quiz',
  USER = 'user',
}

enum questionEventEnumFromBE {
  START_QUESTION= 'START_QUESTION',
  SHOW_QUESTION= 'SHOW_QUESTION',
  SHOW_OPTIONS= 'SHOW_OPTIONS',
  SUBMIT_ANSWER= 'SUBMIT_ANSWER',
  END_QUESTION= 'END_QUESTION',
  GET_GRAPH= 'GET_GRAPH',
  GET_RESULT= 'GET_RESULT',
  GET_LEADERBOARD= 'GET_LEADERBOARD',
}

const Profile = [
  'Group 2.svg',
  'Group 3.svg',
  'Group 4.svg',
  'Group 5.svg',
  'Group 6.svg',
  'Group 7.svg',
  'Group 8.svg',
  'Group 9.svg',
  'Group 10.svg',
  'Group 11.svg',
  'Group 12.svg',
  'Group 13.svg',
  'Group 14.svg',
  'Group 15.svg',
  'Group 16.svg',
  'Group 17.svg',
  'Group 18.svg',
  'Group 19.svg',
  'Group 20.svg',
  'Group 21.svg',
  'Group 22.svg',
  'Group 23.svg',
  'Group 24.svg',
  'Group 25.svg',
  'Group 26.svg',
  'Group 27.svg',
  'Group 28.svg',
  'Group 29.svg',
  'Group 30.svg',
  'Group 31.svg',
  'Group 32.svg',
  'Group 33.svg',
  'Group 34-1.svg',
  'Group 34.svg',
  'Group 35.svg',
  'Group 36.svg',
  'Group 37.svg',
  'Group 38.svg',
  'Group 39.svg',
  'Group 40.svg',
  'Group 41.svg',
  'Group 42.svg',
  'Group 43.svg',
  'Group 44.svg',
  'Group 45.svg',
  'Group 46.svg',
  'Group 47.svg',
  'Group 48.svg',
  'Group 49.svg',
  'Group 50.svg',
  'Group 51.svg',
  'Group 52.svg',
  'Group 53.svg',
  'Group 54.svg',
  'Group 55.svg',
  'Group 56.svg',
  'Group 57.svg',
  'Group 58.svg',
  'Group 59.svg',
  'Group 60.svg',
  'Group 61.svg',
  'Group 62.svg',
  'Group 63.svg',
  'Group 64.svg',
  'Group 65.svg',
  'Group 66.svg',
  'Group 67.svg',
  'Group 69.svg',
  'Group 70.svg',
  'Group 71.svg',
  'Group 72.svg',
  'Group 73.svg',
  'Group 74.svg',
  'Group 75.svg',
  'Group 76.svg',
  'Group 78.svg',
  'Group 79.svg',
  'Group 80.svg',
  'Group 81.svg',
  'Group 82.svg',
  'Group 83.svg',
  'Group 84.svg',
  'Group 85.svg',
  'Group 86.svg',
  'Group 87.svg',
  'Group 88.svg',
  'Group 89.svg',
  'Group 90.svg',
  'Group 91.svg',
  'Group 92.svg',
  'Group 93.svg',
  'Group 94.svg',
  'Group 95.svg',
  'Group 96.svg',
  'Group 97.svg',
  'Group 98.svg',
  'Group 99.svg',
  'Group 100.svg',
  'Group 101.svg',
  'Group 102.svg',
  'Group 103.svg',
  'Group 104.svg',
  'Group 105.svg',
  'Group 106.svg',
  'Group 107.svg',
  'Group 108.svg',
  'Group 109.svg',
  'Group 110.svg',
  'Group 111.svg',
  'Group 112.svg',
  'Group 113.svg',
  'Group 114.svg',
  'Group 115.svg',
  'Group 116.svg',
  'Group 117.svg',
  'Group 118.svg',
  'Group 119.svg',
  'Group 120.svg',
  'Group 121.svg',
  'Group 122.svg',
];

const UserNames = [
  'Anaka',
  'Anala',
  'Anandita',
  'Anang',
  'Ananta',
  'Anbarasi',
  'Anbu',
  'Andal',
  'Bakul',
  'Balabhadra',
  'Balakrishna',
  'Brahma',
  'Chahna',
  'Chaitali',
  'Chaitanya',
  'Deshan',
  'Durga',
  'Gambhira',
  'Gulshan',
  'Hanita',
  'Hansika',
  'Harsha',
  'Harshad',
  'Himesh',
  'Jivan',
  'Johar',
  'Kalinda',
  'Kalindi',
  'Kalynda',
  'Lakshman',
  'Lalika',
  'Mahavir',
  'Mahavira',
  'Mahesh',
  'Monisha',
  'Nalin',
  'Naman',
  'Nameen',
  'Neena',
  'Sareek',
  'Sareeq',
  'Sariq',
  'Shaili',
  'Shakeela',
  'Shashi',
  'Soma',
  'Tanaya',
  'Tanea',
  'Tenaya',
  'Tippoo',
  'Tipu',
  'Udayan',
  'Umed',
  'Vanita',
  'Varoun',
  'Aditi',
  'Ahalya',
  'Aja',
  'Alka',
  'Amar',
  'Amata',
  'Ambar',
  'Anala',
  'Angee',
  'Anila',
  'Anjali',
  'Anju',
  'Anjuli',
  'Anuradha',
  'Artha',
  'Candy',
  'Charu',
  'Damayanti',
  'Devi',
  'Divya',
  'Drisana',
  'Durga',
  'Eshana',
  'Hansika',
  'Indu',
  'Ishani',
  'Jaya',
  'Kaeya',
  'Lata',
  'Monisha',
  'Neesha',
  'Nisha',
  'Rashana',
  'Rashanda',
  'Salena',
  'Salina',
  'Sana',
  'Shaila',
  'Shaili',
  'Shalee',
  'Shaleena',
  'Shalena',
  'Shalene',
  'Shanta',
  'Shantai',
  'Shanti',
  'Shyla',
  'Shylah',
  'Tanaia',
  'Tenaya',
  'Aarav',
  'Aarush',
  'Aayush',
  'Avyaan',
  'Advik',
  'Akarsh',
  'Anay',
  'Atharva',
  'Arhaan',
  'Ahaan',
  'Arnav',
  'Adhrit',
  'Aryaman',
  'Bhaavik',
  'Bhavin',
  'Chitaksh',
  'Daksh',
  'Darshit',
  'Devansh',
  'Dhanuk',
  'Dhairya',
  'Divij',
  'Divit',
  'Divyansh',
  'Ehsaan',
  'Eeshan',
  'Faiyaz',
  'Farhan',
  'Gatik',
  'Gauransh',
  'Hardik',
  'Himmat',
  'Hriday',
  'Hridaan',
  'Hunar',
  'Ishir',
  'Ikshit',
  'Ishaan',
  'Ivaan',
  'Izaan',
  'Jaiyush',
  'Jivin',
  'Kanav',
  'Krishiv',
  'Kiaan',
  'Kushagra',
  'Krish',
  'Lakshay',
  'Lauhit',
  'Lakshit',
  'Manan',
  'Manav',
  'Medhansh',
  'Moksh',
  'Navodit',
  'Nimit',
  'Nirvaan',
  'Nishith',
  'Ohas',
  'Ojas',
  'Parv',
  'Pranay',
  'Pratham',
  'Purab',
  'Raghav',
  'Ranbir',
  'Reyansh',
  'Ritvik',
  'Ryan',
  'Rudransh',
  'Saatvik',
  'Savar',
  'Sahil',
  'Saksham',
  'Samar',
  'Samarth',
  'Shaan',
  'Saihaj',
  'Shayak',
  'Shlok',
  'Shray',
  'Stuvan',
  'Suveer',
  'Taksh',
  'Tanvik',
  'Tanmay',
  'Tejas',
  'Ujjwal',
  'Umang',
  'Uthkarsh',
  'Vaibhav',
  'Vihaan',
  'Viraj',
  'Virat',
  'Vivaan',
  'Yug',
  'Yuvaan',
  'Yuvraj',
  'Zain',
  'Aadrika',
  'Aarya',
  'Akshara',
  'Anahita',
  'Anaisha',
  'Anika',
  'Anvi',
  'Ananya',
  'Arushi',
  'Aradhya',
  'Bhamini',
  'Charvi',
  'Dhriti',
  'Disha',
  'Diva',
  'Drishya',
  'Ela',
  'Eshana',
  'Eenakshi',
  'Hamsika',
  'Hrishita',
  'Hrida',
  'Inaaya',
  'Inayat',
  'Ishita',
  'Ishani',
  'Ira',
  'Jaisvi',
  'Jhanvi',
  'Jivika',
  'Jiya',
  'Kashvi',
  'Kavya',
  'Keya',
  'Khanak',
  'Khushi',
  'Kiara',
  'Kimaya',
  'Lasya',
  'Lavanya',
  'Mahika',
  'Maitreyi',
  'Mannat',
  'Miraya',
  'Mishika',
  'Medhasvini',
  'Myra',
  'Naira',
  'Naisha',
  'Nakshatra',
  'Navya',
  'Nayantara',
  'Nehmat',
  'Nitara',
  'Nitya',
  'Ojasvini',
  'Oorja',
  'Pari',
  'Parinaaz',
  'Pihu',
  'Pranavi',
  'Prisha',
  'Renee',
  'Rhea',
  'Riya',
  'Rudrani',
  'Saanvi',
  'Samaira',
  'Sana',
  'Sara',
  'Seher',
  'Shanaya',
  'Sharanya',
  'Siya',
  'Suhana',
  'Tanya',
  'Tarini',
  'Tiya',
  'Trisha',
  'Urvi',
  'Vanya',
  'Vaidehi',
  'Vardaniya',
  'Vedika',
  'Vihaana',
  'Yashasvini',
  'Yashvi',
  'Zaina',
  'Zaira',
  'Zara',
  'Zoya',
];

enum UserRole {
  TEACHER = 'TEACHER',
  ADMIN = 'ADMIN',
  STUDENT = 'STUDENT',
}

enum Mode {
  LOCAL= 'LOCAL',
  QA = 'QA',
  PROD = 'PROD'
}

const qaAndProd = [String(Mode.QA), String(Mode.PROD)];

enum ToastIdentifier {
  QUIZ_IS_STARTED = 'QUIZ_IS_STARTED'
}

enum QuestionPoints {
  MAX_QUESTION_POINT = 1000,
  MIN_QUESTION_POINT = 500,
}

enum quizEndType {
  FINISH_BUTTON = 'finish_button',
  QUIZ_COMPLETED = 'quiz_completed',
}

enum QuizFlags {
  PRE_QUIZ_TASKS_COMPLETED = 'PRE_QUIZ_TASKS_COMPLETED',
  LIVE_POST_QUIZ_TASKS_COMPLETED = 'LIVE_POST_QUIZ_TASKS_COMPLETED',
  ASYNC_POST_QUIZ_TASKS_COMPLETED = 'ASYNC_POST_QUIZ_TASKS_COMPLETED',
}

enum URL {
  VEDANTU_EXPLORE = 'https://www.vedantu.com/explore',
}

const AnswerFastWaitTime = 2000;

export {
  qaAndProd,
  Mode,
  DateUtils,
  WebView,
  StatusCodes,
  PresenterQuestionStates,
  Subsystem,
  questionEventEnumFromBE,
  Profile,
  UserNames,
  UserRole,
  ToastIdentifier,
  QuestionPoints,
  quizEndType,
  URL,
  QuizFlags,
  AnswerFastWaitTime,
};