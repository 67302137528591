import { fetchData } from '../../../utils/fetch';

import { showToast } from '../common/commonAction';

import type {
  AddQuestionResponseType,
  QuestionType,
  QuestionMetaDataObjectType,
  QuestionMetaDataResponseType,
} from '../../../types/questionTypes';

export const ADD_QUESTION_ACTIONS = {
  ADD_QUESTION_LOADING: 'ADD_QUESTION_LOADING',
  ADD_QUESTION: 'ADD_QUESTION',
  ADD_QUESTION_ERROR: 'ADD_QUESTION_ERROR',
};

type returnStartAction = {
  type : string
}

function start(): returnStartAction {
  return {
    type: ADD_QUESTION_ACTIONS.ADD_QUESTION_LOADING,
  };
}

type addQuestionActionType = {
  type: string,
  question: QuestionType,
  questionMetaData: QuestionMetaDataObjectType[],
}

function success(data: QuestionType, questionMetaData: QuestionMetaDataObjectType[]): addQuestionActionType {
  return {
    type: ADD_QUESTION_ACTIONS.ADD_QUESTION,
    question: data,
    questionMetaData,
  };
}

type addQuestionActionErrorType = {
  type: string,
  error: any,
}

function failure(error: any): addQuestionActionErrorType {
  return {
    type: ADD_QUESTION_ACTIONS.ADD_QUESTION_ERROR,
    error,
  };
}

type getQuestionParamType = {
  questionId: string,
  quizId: number,
}

function getQuestionFromQuestionIdUrl({ questionId, quizId }: getQuestionParamType): Promise<AddQuestionResponseType> {
  return fetchData({
    method: 'POST', url: '/question-library/v1/admin/question-library', credentials: 'include', data: { questionId, quizId },
  });
}

function appendToQuestionMetaData(questionMetaDataId: string, currentQuestionId: string, questionMetadata: QuestionMetaDataObjectType[]) {
  let metaDataIndex = 0;
  const metaData = {
    id: questionMetaDataId,
    type: 'SCQ',
    hidden: false,
  };
  questionMetadata.every((m: QuestionMetaDataObjectType, index: number) => {
    if (m.id === currentQuestionId) {
      metaDataIndex = index + 1;
      return false;
    }
    return true;
  });
  const newQuestionMetadata = [...questionMetadata.slice(0, metaDataIndex), metaData, ...questionMetadata.slice(metaDataIndex)];
  return newQuestionMetadata;
}

function postQuestionMetaData(questionMetaData: QuestionMetaDataObjectType[], quizId: number): Promise<QuestionMetaDataResponseType> {
  return fetchData({
    method: 'POST', url: `/question-metadata/v1/admin/quiz/${quizId}`, credentials: 'include', data: questionMetaData,
  });
}

// api call for one questionId
export function getQuestionFromQuestionId(questionId: string, quizId: number, currentQuestionId: string, questionMetadata: QuestionMetaDataObjectType[]): any {
  return async (dispatch: any) => {
    dispatch(start());
    try {
      const data = await getQuestionFromQuestionIdUrl({ questionId, quizId });
      const questionMetaDataId = data.response?.id;
      const newQuestionMetaData = appendToQuestionMetaData(questionMetaDataId, currentQuestionId, questionMetadata);
      await postQuestionMetaData(newQuestionMetaData, quizId);
      dispatch(showToast('Question added'));
      return dispatch(success(data.response, newQuestionMetaData));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      // @ts-ignore
      dispatch(showToast(`Error while adding Question ${error?.error?.message}`));
      return dispatch(failure(error));
    }
  };
}
