/* eslint-disable no-console */
import Router from 'next/router';
import { config } from '../config/config';
import { store } from '../redux/store';
import { URL } from './enums';

declare global {
  interface Window {
    ReactNativeWebView: any;
    opera: any;
  }
}

const vCookies = {
  getItem(sKey: string): string | null {
    if (!sKey || typeof window !== 'object') {
      return null;
    }
    return (
      decodeURIComponent(
        document.cookie.replace(
          new RegExp(
            // eslint-disable-next-line no-useless-escape
            `(?:(?:^|.*;)\\s*${encodeURIComponent(sKey).replace(/[\-\.\+\*]/g, '\\$&')}\\s*\\=\\s*([^;]*).*$)|^.*$`,
          ),
          '$1',
        ),
      ) || null
    );
  },
};

function getAbsImagePath(url: string): string {
  // eslint-disable-next-line prefer-destructuring
  const CDN_URL = process.env.CDN_URL;
  return `${CDN_URL}${url}`;
}

export {
  getAbsImagePath,
  vCookies,
};

export const getRandomInt = (max: number) => Math.floor(Math.random() * max);

export function isMobileOrTablet() {
  let check = false;
  (function (a) {
    // eslint-disable-next-line max-len
    if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) {
      check = true;
    }
  }(navigator.userAgent || navigator.vendor || window.opera));
  return check;
}

export const updateCDNImageUrl = (text: string) => text;
// const url = 'http://d2ok199g7vybzn.cloudfront.net';
// const currentUrl = process.env.CDN_URL;
// if (currentUrl) {
//   text.replaceAll(url, currentUrl);
// }

export const updateRedirectionUrl = (url: string) => {
  if (url.startsWith('http')) {
    return url;
  }

  return `https://${url}`;
};

export const handleRedirection = (redirectionUrl: string) => {
  const { doubtApp } = Router.query;
  console.log('handleRedirection ', 'redirectionUrl: ', redirectionUrl, 'doubtApp: ', doubtApp);
  const isAppURL = !!redirectionUrl.includes('/app/');
  if (isAppURL) {
    if (doubtApp) {
      window?.ReactNativeWebView?.postMessage?.(
        JSON.stringify({
          type: 'DEEPLINKING',
          url: updateRedirectionUrl(redirectionUrl),
        }),
      );
    } else Router.push({ pathname: updateRedirectionUrl(URL.VEDANTU_EXPLORE) });
  } else Router.push({ pathname: updateRedirectionUrl(redirectionUrl) });
};

export const sendClickStreamEvent = (category: any, action: any, label: any, value: any, obj: any) => {
  const { userId, email } = store.getState().userData;
  if (!obj) obj = {};
  if (!obj.userId) obj.userId = userId;
  if (!obj.email) obj.email = email;
  try {
    // @ts-ignore     // to avoid  error TS2304: Cannot find name 'clickStream'.
    clickStream?.pushEvent(category, action, label, value, obj);
  } catch (error) {
    console.warn(`Error while sending event to click stream: ${error}`);
  }
};

export const getDateTimeIST = (date: number) => {
  const d = new Date(date);
  const reqDateFormat = d.toLocaleDateString(); // dd/mm/yyyy
  const reqTimeFormat = `${d.getHours()}:${d.getMinutes()}:${d.getSeconds()}`; // hh:mm:ss
  return `${reqDateFormat} ${reqTimeFormat}`;
};

export const getImageUrl = (source: string) => {
  if (source) {
    const awsUrl = 'amazonaws.com';
    const googleUrl = 'googleapis.com';
    const indexOfAws = source.indexOf(awsUrl);
    const indexOfGoogle = source.indexOf(googleUrl);
    const mode = config.mode.toLowerCase();
    let vdntUrl = '';
    if (mode && mode === 'prod') {
      vdntUrl = 'https://vquiz-fe.vedantu.com';
    } else {
      vdntUrl = 'https://vquiz-fe.vedantuint.net';
    }
    if (!(indexOfAws > -1 || indexOfGoogle > -1)) {
      return source;
    } if (indexOfAws > -1) {
      console.log('is link from amazon aws', indexOfAws > -1);
      const adder = awsUrl.length;
      const awsPart = source.slice(0, indexOfAws + adder);
      console.log('reomve aws part', awsPart);
      const newUrl = source.replace(awsPart, vdntUrl);
      console.log('new url', newUrl);
      return newUrl;
    }
    console.log('is link from google storage', indexOfGoogle > -1);
    const indexString = 'wavebook-media';
    const indexStringPos = source.indexOf(indexString);
    const googlePart = source.slice(0, indexStringPos);
    console.log('reomve google part', googlePart);
    vdntUrl = `${vdntUrl}/whiteboardPdf/${mode.toUpperCase()}/media/`;
    const newUrl = source.replace(googlePart, vdntUrl);
    console.log('new url', newUrl);
    return newUrl;
  }
  return '';
};

export const getUpdatedLbData = (lbResult: any, lbRelativeScore: any, totalScore: number, userData: any) => {
  const updatedLbData = [];
  const { name, profile, userId } = userData;
  let ownRank = 0;
  let rank = 0;
  console.log('sdcdscas', lbResult, lbRelativeScore, totalScore, userData);
  if (!lbResult.length && !lbRelativeScore.length) {
    return {
      updatedLbData: [{
        userId,
        name,
        score: totalScore || 0,
        profile,
        rank: 1,
      }],
      rank: 1,
    };
  }
  if (totalScore > lbResult[0]?.score) {
    rank += 1;
    updatedLbData.push({
      userId,
      name,
      score: totalScore || 0,
      profile,
      rank,
    });
    ownRank = rank;
  }
  for (let i = 0; i < lbResult.length - 1; i += 1) {
    if (lbResult[i].userId !== userData.userId) {
      updatedLbData.push({ ...lbResult[i], rank: rank += 1 });
    }
    if (totalScore < lbResult[i].score && totalScore > lbResult[i + 1].score) {
      updatedLbData.push({
        userId,
        name,
        score: totalScore || 0,
        profile,
        rank: rank += 1,
      });
      ownRank = rank;
    }
  }
  if (lbResult[lbResult.length - 1]?.userId !== userData.userId) {
    updatedLbData.push({ ...lbResult[lbResult.length - 1], rank: rank += 1 });
  }
  if (!ownRank) {
    for (let i = 0; i < lbRelativeScore.length; i += 1) {
      if (totalScore < lbRelativeScore[i] && totalScore > lbRelativeScore[i + 1]) {
        ownRank = 10 + i + 1;
      }
      rank += 1;
    }
  }
  if (!ownRank) {
    updatedLbData.push({
      userId,
      name,
      score: totalScore || 0,
      profile,
      rank: rank += 1,
    });
    ownRank = rank;
  }
  return { updatedLbData: updatedLbData.slice(0, 10), rank: ownRank };
};