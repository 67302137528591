import { configureStore } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-extraneous-dependencies -- reduxtoolkit installs it
import { combineReducers } from 'redux';
import questions from './actionAndReducers/questions/questionsReducer';
import quizData from './actionAndReducers/quizData/quizDataReducer';
import userData from './actionAndReducers/user/userReducer';
import common from './actionAndReducers/common/commonReducer';

export const rootReducer = combineReducers({
  questions,
  quizData,
  userData,
  common,
});

export const store = configureStore({
  reducer: {
    questions,
    quizData,
    userData,
    common,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
