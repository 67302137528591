const common = {
  appVersion: '1',
  androidLink: 'https://play.google.com/store/apps/details?id=com.vedantu.student&hl=en',
  globalStaticVersion: '16.51',
  pubnubPublish: 'pub-c-774eb469-99e0-4849-9065-741a4bca2263',
  pubnubSubscribe: 'sub-c-7df8f89e-cb44-11e5-a9b2-02ee2ddab7fe',
  platformNotifPort: '',
  opentokApiKey: '44815322',
  click2call: '080 39658593',
  customerCareNumber: '+91 988-660-2456',
  customerCareTollFree: '1800-120-456-456',
  customerCareTiming: '(Mon-Sun: 9am - 9pm IST)',
  plansStartTime: '1426336200000',
  plansShowSale: 'false',
  showBanner: 'false',
  marquesPhy: '5750790484393984',
  marquesChem: '5101952727777280',
  marquesMath: '6247616329285632',
  latestTNC: 'v9',
  latestPrivacyPolicy: 'v6',
  youtubeApiKeyVersion: 'v3',
  youtubeKey: 'AIzaSyCjt5AMfCqkUW_DoDQ-SdiwjPm70yed69g',
  signupBonus: '500',
  truerAvailibility: '0.8',
  MAX_RECHARGE_RUPEES: '500000',
  moengageAppId: '3VTRRG9T5M0L5V3S53GZ10R5',
  vskMoengageAppId: 'X2EPYCRSQZTGNX6A4GXE0RMQ',
  abTestingName: '_homepage_version',
  OTFCourseTrialPeriod: '7',
  gstPercentage: '18',
  smartlookAppId: '54a52234ddf7e931c84e8fd62420556237ae5257',
  sentryDSN: 'https://d06f9e06282d4810b672204a25948107@o30136.ingest.sentry.io/6392255',
  showEmailInProfileBuilder: false,
  platformFetch: {
    token: {
      frontend: 'VEDANTU_FRONT_END',
      id: '225F7',
      secret: {
        key: 'ErG3ke0MsR8vtd35GyVri1hJea212wdr',
      },
    },
  },
  qaPort: '9998',
  amplitude: '569cc3739e11749a54533d4c693a38d0',
};
console.log(common.globalStaticVersion);
module.exports = common;