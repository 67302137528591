import type { QuestionMetaDataObjectType, QuestionMetaDataResponseType } from '../../../types/questionTypes';
import { fetchData } from '../../../utils/fetch';

import { showToast } from '../common/commonAction';

export const DELETE_QUESTION_ACTIONS = {
  DELETE_QUESTION: 'DELETE_QUESTION',
  DELETE_QUESTION_LOADING: 'DELETE_QUESTION_LOADING',
  DELETE_QUESTION_ERROR: 'DELETE_QUESTION_ERROR',
};

type ReturnStartAction = {
  type : string
}

function start(): ReturnStartAction {
  return {
    type: DELETE_QUESTION_ACTIONS.DELETE_QUESTION_LOADING,
  };
}

type DeleteSuccessActionType = {
  type: string,
  questionMetaData: QuestionMetaDataObjectType[]
}

export const success = (questionMetaData: QuestionMetaDataObjectType[]): DeleteSuccessActionType => ({
  type: DELETE_QUESTION_ACTIONS.DELETE_QUESTION,
  questionMetaData,
});

type DeleteQuestionActionErrorType = {
  type: string,
  error: any,
}

function failure(error: any): DeleteQuestionActionErrorType {
  return {
    type: DELETE_QUESTION_ACTIONS.DELETE_QUESTION_ERROR,
    error,
  };
}

function deleteQuestionMetaData(currentQuestionId: string, questionMetadata: QuestionMetaDataObjectType[]): QuestionMetaDataObjectType[] {
  const newQuestionMetadata = questionMetadata.map((m: QuestionMetaDataObjectType) => {
    if (m.id === currentQuestionId) {
      return { ...m, hidden: true };
    }
    return m;
  });
  return newQuestionMetadata;
}

function postQuestionMetaData(questionMetaData: QuestionMetaDataObjectType[], quizId: number): Promise<QuestionMetaDataResponseType> {
  return fetchData({
    method: 'POST', url: `/question-metadata/v1/admin/quiz/${quizId}`, credentials: 'include', data: questionMetaData,
  });
}

// api call to delete and save action id
export const handleDeleteQuestionAction = (currentQuestionId: string, questionMetadata: QuestionMetaDataObjectType[], quizId: number): any => async (dispatch: any) => {
  dispatch(start());
  try {
    const newQuestionMetaData = deleteQuestionMetaData(currentQuestionId, questionMetadata);
    await postQuestionMetaData(newQuestionMetaData, quizId);
    dispatch(showToast('Question deleted'));
    return dispatch(success(newQuestionMetaData));
  } catch (error) {
    // @ts-ignore
    dispatch(showToast(`Error deleteing Question ${error?.error?.message}`));
    return dispatch(failure(error));
  }
};
