import { QuizEntityDataType, QuizProgressStates } from '../../../types/quiz';
import { QUIZ_DATA_ACTIONS } from './quizDataAction';

type initialStateType = {
    data: QuizEntityDataType | {},
    inPresentMode: boolean,
    isQuizStarted: boolean,
    success: boolean
    isSocketConnected: boolean,
    inBufferMode: boolean,
  };

export const initialState: initialStateType = {
  data: {},
  inPresentMode: false,
  isQuizStarted: false,
  inBufferMode: false,
  success: false,
  isSocketConnected: false,
};

// eslint-disable-next-line default-param-last
export default function quizDataReducer(state = initialState, action: any) {
  switch (action.type) {
    case QUIZ_DATA_ACTIONS.TOGGLE_PRESENT_MODE:
      return { ...state, inPresentMode: !state.inPresentMode };
    case QUIZ_DATA_ACTIONS.UPDATE_QUIZ_DATA: {
      const inPresentMode = action.quizData.progressState === QuizProgressStates.LIVE_STARTED;
      const success = true;
      return {
        ...state, data: action.quizData, inPresentMode, success,
      };
    }
    case QUIZ_DATA_ACTIONS.UPDATE_QUIZ_STARTED:
      return { ...state, isQuizStarted: action.value };
    case QUIZ_DATA_ACTIONS.UPDATE_SOCKET_CONNECTED:
      return { ...state, isSocketConnected: true };
    case QUIZ_DATA_ACTIONS.UPDATE_QUIZ_BUFFER_MODE:
      return { ...state, inBufferMode: action.value };
    default:
      return state;
  }
}
