/* eslint-disable no-underscore-dangle */
import { fetchData } from '../../../utils/fetch';

import type {
  QuestionType,
  QuestionMetaDataObjectType,
  QuestionMetaDataResponseType,
  FetchQuestionResponseType,
  questionLibraryObjectType,
} from '../../../types/questionTypes';

import type { RootState } from '../../store';

export const FETCH_QUESTIONS_ACTIONS = {
  FETCH_QUESTIONS_LOADING: 'FETCH_QUESTIONS_LOADING',
  FETCH_QUESTIONS: 'FETCH_QUESTIONS',
  FETCH_QUESTIONS_ERROR: 'FETCH_QUESTIONS_ERROR',
};

type returnStartAction = {
  type : string
}

type createQuestionArrayType = {
  questions: QuestionType[],
  currentIndex: number | undefined,
  consistencyData: any,
}

type fetchQuestionActionType = {
  type: string,
  data: createQuestionArrayType,
  questionMetaData: QuestionMetaDataObjectType[]
}

type fetchQuestionActionErrorType = {
  type: string,
  error: any,
}

type getQuestionParamType = {
  quizId: number,
}

function start(): returnStartAction {
  return {
    type: FETCH_QUESTIONS_ACTIONS.FETCH_QUESTIONS_LOADING,
  };
}

function success(data: createQuestionArrayType, questionMetaData: QuestionMetaDataObjectType[]): fetchQuestionActionType {
  return {
    type: FETCH_QUESTIONS_ACTIONS.FETCH_QUESTIONS,
    data,
    questionMetaData,
  };
}

function failure(error: any): fetchQuestionActionErrorType {
  return {
    type: FETCH_QUESTIONS_ACTIONS.FETCH_QUESTIONS_ERROR,
    error,
  };
}

function getQuestionLibraryUrl({ quizId }: getQuestionParamType): Promise<FetchQuestionResponseType> {
  return fetchData({
    method: 'GET', url: `/question-library/v1/admin/question-library/${quizId}`, credentials: 'include',
  });
}

function getQuestionMetaData({ quizId }: getQuestionParamType): Promise<QuestionMetaDataResponseType> {
  return fetchData({
    method: 'GET', url: `/question-metadata/v1/admin/quiz/${quizId}`, credentials: 'include',
  });
}

function getConsistency({ quizId }: getQuestionParamType):Promise<any> {
  return fetchData({
    method: 'GET', url: `/assessment/v1/admin/current-state/quiz/${quizId}`, credentials: 'include',
  });
}

export function createQuestionsArray(questions: QuestionType[], questionMetadataArray: QuestionMetaDataObjectType[], consistencyData?: any): createQuestionArrayType {
  const questionLibraryObject: questionLibraryObjectType = {}; // data structure {quizId: ""}
  const questionsSorted: QuestionType[] = [];
  let currentIndex;
  let totalQuestions = 0;
  questions?.forEach((question: QuestionType) => {
    questionLibraryObject[question.id] = question;
  });

  questionMetadataArray?.forEach((questionMetaData: QuestionMetaDataObjectType) => {
    if (!questionMetaData.hidden && questionLibraryObject[questionMetaData.id]) {
      totalQuestions += 1;
      questionsSorted.push(questionLibraryObject[questionMetaData.id]);
      if (questionMetaData.id === consistencyData?.currentQuestion?.questionLibraryId) {
        currentIndex = totalQuestions - 1;
      }
    }
  });

  return { questions: questionsSorted, currentIndex, consistencyData };
}

// api call for one questionId
export function getQuestions(quizId: number): any {
  return async (dispatch: any, getState: () => RootState) => {
    dispatch(start());
    try {
      const state = getState();
      const { isQuizStarted } = state.quizData;
      const { inPresentMode } = state.quizData;
      let value = [];
      if (isQuizStarted && inPresentMode) {
        value = await Promise.all([getQuestionLibraryUrl({ quizId }), getQuestionMetaData({ quizId }), getConsistency({ quizId })]);
      } else {
        value = await Promise.all([getQuestionLibraryUrl({ quizId }), getQuestionMetaData({ quizId })]);
      }
      console.log(value);
      const data = createQuestionsArray(value[0].response, value[1].response.metaData, value[2]?.response);
      return dispatch(success(data, value[1].response.metaData));
    } catch (error) {
      return dispatch(failure(error));
    }
  };
}
