import { config } from '../config/config';
import { Mode, Subsystem } from './enums';

type RequestCredentials = 'include' | 'omit' | 'same-origin'

type fetchType = {
  method?: string,
  url?: string,
  data?: any,
  credentials?: RequestCredentials| undefined
  subsystem?: string,
  customUrl?: string,
}

const getURL = (subsystem: string): string => {
  switch (subsystem) {
    case Subsystem.USER:
      return config.platformNew.user.domain;
    default:
      // return `${config.quiz.url}${config.mode === Mode.PROD ? `/${subsystem}` : ''}`;
      return `${config.quiz.url}`;
  }
};

async function fetchData<T>({
  method = 'GET',
  url = '',
  data = undefined,
  credentials = undefined,
  subsystem = 'quiz',
  customUrl = '',
}: fetchType):Promise<T> {
  let urlToSend = `https://${getURL(subsystem)}${url}`;
  if (customUrl) {
    urlToSend = customUrl;
  }
  const response = await fetch(urlToSend, {
    method,
    credentials,
    body: data ? JSON.stringify(data) : undefined,
  });

  if (!response.ok) {
    throw await response.json();
  }
  return response.json() as Promise<T>;
}

export { fetchData };