import type { QuizEntityDataType } from '../../../types/quiz';

export const QUIZ_DATA_ACTIONS = {
  TOGGLE_PRESENT_MODE: 'TOGGLE_PRESENT_MODE',
  UPDATE_QUIZ_DATA: 'UPDATE_QUIZ_DATA',
  UPDATE_QUIZ_STARTED: 'UPDATE_QUIZ_STARTED',
  UPDATE_SOCKET_CONNECTED: 'UPDATE_SOCKET_CONNECTED',
  UPDATE_QUIZ_BUFFER_MODE: 'UPDATE_QUIZ_BUFFER_MODE',
};

export type ToggleReturnType = {
    type: string,
}

export function togglePresentMode(): ToggleReturnType {
  return {
    type: QUIZ_DATA_ACTIONS.TOGGLE_PRESENT_MODE,
  };
}

type UpdateQuizDataType = {
  type: string,
  quizData: QuizEntityDataType | undefined
}

export function updateQuizData(quizData: QuizEntityDataType | undefined):UpdateQuizDataType {
  return {
    type: QUIZ_DATA_ACTIONS.UPDATE_QUIZ_DATA,
    quizData,
  };
}

export const updateBufferTimeMode = (value: boolean) => ({
  type: QUIZ_DATA_ACTIONS.UPDATE_QUIZ_BUFFER_MODE,
  value,
});

export const updateQuizStarted = (value: boolean) => ({
  type: QUIZ_DATA_ACTIONS.UPDATE_QUIZ_STARTED,
  value,
});

export const updateSocketConnected = () => ({
  type: QUIZ_DATA_ACTIONS.UPDATE_SOCKET_CONNECTED,
});