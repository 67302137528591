import { TimeDifferenceData } from '../../../types/quiz';
import { Subsystem } from '../../../utils/enums';
import { fetchData } from '../../../utils/fetch';

export const TIME_DIFF_ACTIONS = {
  TIME_DIFF: 'TIME_DIFF',
  TIME_DIFF_LOADING: 'TIME_DIFF_LOADING',
  TIME_DIFF_ERROR: 'TIME_DIFF_ERROR',
};

type ReturnStartAction = {
    type : string
  }

function start(): ReturnStartAction {
  return {
    type: TIME_DIFF_ACTIONS.TIME_DIFF_LOADING,
  };
}

function success(data: TimeDifferenceData) {
  return {
    type: TIME_DIFF_ACTIONS.TIME_DIFF,
    data,
  };
}

type ActionErrorType = {
    type: string,
    error: any,
}

function failure(error: any):ActionErrorType {
  return {
    type: TIME_DIFF_ACTIONS.TIME_DIFF_ERROR,
    error,
  };
}

function getTimeDifference(): Promise<TimeDifferenceData> {
  return fetchData({
    method: 'GET',
    url: `/api/ping/timeDifference/${Date.now()}`,
    credentials: 'include',
    customUrl: `${process.env.CDN_URL}/api/ping/timeDifference/${Date.now()}`,
  });
}

export const getTimeDifferenceAction = () => async (dispatch: any) => {
  dispatch(start());
  try {
    const data = await getTimeDifference();
    return dispatch(success(data));
  } catch (error) {
    return dispatch(failure(error));
  }
};