import { fetchData } from '../../../utils/fetch';
import { EditQuestionResponseType } from '../../../types/questionTypes';

import { showToast } from '../common/commonAction';

export const EDIT_QUESTION_ACTIONS = {
  EDIT_QUESTION_LOADING: 'EDIT_QUESTION_LOADING',
  EDIT_QUESTION: 'EDIT_QUESTION',
  EDIT_QUESTION_ERROR: 'EDIT_QUESTION_ERROR',
};

type returnStartAction = {
  type : string
}

function start(): returnStartAction {
  return {
    type: EDIT_QUESTION_ACTIONS.EDIT_QUESTION_LOADING,
  };
}

type EditSuccessType = {
  leaderboard: boolean
  showSolution: boolean
  durationInSecs: number
}

type editQuestionActionType = {
  type: string,
  question: EditSuccessType,
}

function success(data: EditSuccessType): editQuestionActionType {
  return {
    type: EDIT_QUESTION_ACTIONS.EDIT_QUESTION,
    question: data,
  };
}

type editQuestionActionErrorType = {
  type: string,
  error: any,
}

function failure(error: any): editQuestionActionErrorType {
  return {
    type: EDIT_QUESTION_ACTIONS.EDIT_QUESTION_ERROR,
    error,
  };
}

type getQuestionParamType = {
  id: string,
  leaderboard: boolean,
  showSolution: boolean,
  durationInSecs: number,
}

function editQuestionFromQuestionIdUrl({
  id, leaderboard, showSolution, durationInSecs,
}: getQuestionParamType): Promise<EditQuestionResponseType> {
  return fetchData({
    method: 'PUT', url: `/question-library/v1/admin/question-library/library/${id}`, credentials: 'include', data: { leaderboard, showSolution, durationInSecs },
  });
}

// api call
export function editQuestionFromQuestionId(id: string, leaderboard: boolean, showSolution: boolean, durationInSecs: number): any {
  return async (dispatch: any) => {
    dispatch(start());
    try {
      await editQuestionFromQuestionIdUrl({
        id, leaderboard, showSolution, durationInSecs,
      });
      dispatch(showToast('Question edit success'));
      return dispatch(success({ leaderboard, showSolution, durationInSecs }));
    } catch (error) {
      // @ts-ignore
      dispatch(showToast(`Error while editing question  ${error?.error?.message}`));
      return dispatch(failure(error));
    }
  };
}
